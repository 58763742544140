import * as React from "react";
import MainLayout from "../../Layouts";
import OrderContent from "../../Order/Details/content";
import MerchantLayout from "../Layout";

const MerchantOrderDetails = () => {
  return (
    <MainLayout>
      <MerchantLayout>
        <OrderContent />
      </MerchantLayout>
    </MainLayout>
  );
};
export default MerchantOrderDetails;
