import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  // state slice name
  name: "user",
  // initial value
  initialState: {
    value: null,
  },
  // reducer to change the value of initial state
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    logout: (state) => {
      state.value = null;
    },
  },
});
export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;
