import { AutoComplete, Button, Input, Space, Tag } from "antd";
import axios from "axios";
import moment from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import MainLayout from "../../Layouts";
import MerchantLayout from "../Layout";
import InvoiceList from "./InvoiceList";

const MerchantSettledList = () => {
  return (
    <MainLayout>
      <MerchantLayout>
        <InvoiceList fromMerchant={true} />
      </MerchantLayout>
    </MainLayout>
  );
};
export default MerchantSettledList;
