import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Input,
  Pagination,
  Select,
  Space,
  Tag,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { statusArray, statusIteams } from "../../utils/status";
import Loader from "../common/Loader";
import PageTitle from "../common/PageTitle";
import { errorNotification, updateNotification } from "../../utils/notify";

const Order = ({
  shopID,
  inMerchant = false,
  productStatus,
  paidStatus,
}: {
  shopID?: string;
  inMerchant?: boolean;
  productStatus?: any;
  paidStatus?: string;
}): ReactElement => {
  // Pagination States
  const [page, setPageNumber] = useState<any>(0);
  const [limit, setLimit] = useState<any>(20);

  // Filter States
  const [status, setStatus] = useState<any>(productStatus);
  const [paymentStatus, setPaymentStatus] = useState<any>(paidStatus);
  const [shopId, setShopId] = useState<any>(shopID);
  const [orderId, setOrderId] = useState<any>();
  const merchantId = useParams();

  // Check All States
  const [selectedOrders, setSelectedOrders] = useState<any>([]);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  // Api States
  const navigate = useNavigate();
  const token = useSelector((state: any) => state.token.value);

  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: [],
  });

  const [orderOption, setOrderOption] = useState<any>({
    loading: false,
    list: [],
  });

  const [merchantOption, setMerchantOption] = useState<any>({
    loading: false,
    list: [],
  });

  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  // All Order Data
  const url = `${process.env.REACT_APP_ORDER_API}/admin/order?type=JC_PARCEL`;
  const fetchOrderList = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setOrderData({ loading: true, data: [] });
    try {
      const response = await axios
        .get(
          url +
            (orderId ? `&orderIdOrBaseOrderId=${orderId}` : ``) +
            (shopId ? `&shopId=${shopId}` : ``) +
            (paymentStatus ? `&paymentStatus=${paymentStatus}` : ``) +
            (status ? `&status=${status}` : ``) +
            (from ? `&from=${from}` : ``) +
            (to ? `&to=${to}` : ``) +
            (page
              ? `&page=${
                  page == orderData?.data?.currentPageNumber ? 0 : page || 0
                }`
              : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setOrderData({ loading: false, data: res?.data });
          setCheckedList(res.data.orders.map((order: any) => order));
        });
    } catch (error) {
      console.error(error.message);
    }
  }, [page, limit, orderId, status, range, shopId, paymentStatus]);

  useEffect(() => {
    fetchOrderList();
  }, [page, limit, orderId, status, range, shopId, paymentStatus]);

  // Filter By Order Id
  const getOrderOption = useCallback(
    async (val) => {
      setOrderOption({ loading: true, list: [] });
      try {
        const response = await axios
          .get(
            url +
              (val ? `&orderIdOrBaseOrderId=${val}` : ``) +
              (shopId ? `&shopId=${shopId}` : ``) +
              (status ? `&status=${status}` : ``) +
              (limit ? `&limit=${limit}` : ``) +
              (paymentStatus ? `&paymentStatus=${paymentStatus}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setOrderOption({
              loading: false,
              list: res.data?.orders?.map((order: { orderId: any }) => ({
                label: `${order.orderId}`,
                value: order?.orderId,
              })),
            });
          });
      } catch (error) {
        setOrderOption({ loading: false, list: [] });
        console.error(error.message);
      }
    },
    [shopId, status, paymentStatus]
  );
  useEffect(() => {
    getOrderOption(``);
  }, [getOrderOption]);

  // Filter By Merchant
  const getMerchantOption = useCallback(async (val) => {
    setMerchantOption({ loading: true, list: [] });
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_RIDER_API}/business/all?limit=${limit}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMerchantOption({
            loading: false,
            list: res.data?.businesses?.map((merchant) => ({
              label: `${merchant?.companyName?.toUpperCase()} `,
              value: merchant?.id,
            })),
          });
        });
    } catch (error) {
      setMerchantOption({ loading: false, list: [] });
      console.error(error.message);
    }
  }, []);
  useEffect(() => {
    getMerchantOption(``);
  }, [getMerchantOption]);

  // Reset Filter
  const resetFilter = () => {
    setOrderId(null);
    setShopId(shopID);
    setRange({
      from: null,
      to: null,
    });
    setStatus(productStatus || null);
    setPaymentStatus(paidStatus);
  };

  // Check All Function
  const onChangeCheckbox = (e: any) => {
    setSelectedOrders((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length && checkedList.length <= selectedOrders.length
    );

    setCheckAll(checkedList.length === selectedOrders.length);
  };

  const onCheckAllChange = (e: any) => {
    setSelectedOrders(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  // Drawer Function
  const [open, setOpen] = useState(false);

  const submitSettlement = async () => {
    const readyData = {
      orderIds: selectedOrders?.map((order) => order?.orderId),
    };
    // console.log(readyData);
    if (selectedOrders) {
      await fetch(
        `https://order.jachai-rider.com:443/api/v1/admin/order/settle-parcel`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            updateNotification("Submitted successfully", "success");
            setOpen(false);
            // window.location.reload();
            fetchOrderList();
          } else {
            errorNotification("Failed");
          }
        })
        .catch((err) => {
          console.log(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  // Row Click
  const rowClick = (order) => {
    if (inMerchant) {
      !paidStatus &&
        navigate(`/merchants/${merchantId.merchantId}/order/${order?.orderId}`);
    } else {
      navigate(`/orders/${order?.orderId}/details`);
    }
  };
  const totalAmount = selectedOrders?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.total,
    0
  );
  const totalDeliveryCharge = selectedOrders?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue?.parcelOrder?.deliveryCharge,
    0
  );

  return (
    <>
      <PageTitle
        title={`${
          (paymentStatus === "UNPAID" && "Unpaid") ||
          (paymentStatus === "Paid" && "Paid") ||
          "All"
        } Order List`}
        subTitle={`(${orderData.data?.totalElements})`}
        extra={
          <Space wrap>
            <AutoComplete
              options={orderOption?.list}
              onSelect={(val) => setOrderId(val)}
              onChange={(e) => setOrderId(e)}
              onSearch={(val) => getOrderOption(val)}
              filterOption={true}
            >
              <Input.Search
                placeholder="Filter by Order ID"
                className="icon-btn"
              />
            </AutoComplete>
            {!inMerchant && (
              <AutoComplete
                options={merchantOption?.list}
                onSelect={(val) => setShopId(val)}
                onChange={(e) => setShopId(e)}
                onSearch={(val) => getMerchantOption(val)}
                filterOption={true}
              >
                <Input.Search
                  placeholder="Filter by Merchant"
                  className="icon-btn"
                />
              </AutoComplete>
            )}

            <DatePicker.RangePicker
              inputReadOnly
              allowClear
              onChange={(e, f) => {
                console.log("momnet", moment.utc(f[0])?.toISOString());
                setRange({ from: f?.[0], to: f?.[1] });
              }}
            />
            {!paidStatus && (
              <Select
                showSearch
                allowClear
                placeholder="Select Status"
                onChange={(e) => setStatus(e)}
                onSearch={(e) => setStatus(e)}
                filterOption={true}
                options={statusArray?.map((data) => ({
                  label: data?.split("_").join(" "),
                  value: data,
                }))}
              ></Select>
            )}
            {!paidStatus && (
              <Select
                showSearch
                allowClear
                placeholder="Select Payment Status"
                onChange={(e) => setPaymentStatus(e)}
                onSearch={(e) => setPaymentStatus(e)}
                filterOption={true}
                options={[
                  {
                    value: "Paid",
                    label: "Paid",
                  },
                  {
                    value: "UNPAID",
                    label: "Unpaid",
                  },
                ]}
              ></Select>
            )}

            <Button
              type="primary"
              onClick={resetFilter}
              className="!bg-[#bf2a2c] hover:!bg-red-600"
            >
              Reset
            </Button>
            {paidStatus && (
              <Button
                className="!bg-teal-700 !text-white"
                disabled={!selectedOrders.length}
                onClick={showDrawer}
              >
                Action
              </Button>
            )}
          </Space>
        }
      />
      {orderData?.loading ? (
        <Loader />
      ) : (
        <div className="table-responsive">
          <table className="table-auto dataTable">
            <thead>
              <tr>
                <th>
                  {paidStatus ? (
                    <Checkbox
                      onChange={onCheckAllChange}
                      indeterminate={indeterminate}
                      checked={
                        checkAll ||
                        selectedOrders?.length === checkedList?.length
                      }
                      disabled={!orderData.data?.orders?.length}
                      className="!text-white"
                    >
                      Check all
                    </Checkbox>
                  ) : (
                    "Order Id"
                  )}
                </th>
                <th>Order Date</th>
                <th>Collected Amount</th>
                <th>Delivery Charge</th>
                <th>Method</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orderData?.data?.orders?.length ? (
                orderData?.data?.orders?.map((order, index) => (
                  <tr
                    key={index}
                    className="hover:bg-slate-50 cursor-pointer transition ease-in-out"
                    onClick={() => rowClick(order)}
                  >
                    <td>
                      {paidStatus ? (
                        <Checkbox
                          onChange={() => onChangeCheckbox(order)}
                          checked={selectedOrders?.find(
                            (item: any) => order === item
                          )}
                          value={order}
                        >
                          {order?.orderId}
                        </Checkbox>
                      ) : (
                        order?.orderId
                      )}
                    </td>
                    <td>
                      <p>{moment(order?.createdAt).format("LL")}</p>
                    </td>
                    <td>&#2547; {order?.total || 0}</td>
                    <td>&#2547; {order?.parcelOrder?.deliveryCharge || 0}</td>
                    <td>{order?.paymentMethod}</td>
                    <td>
                      <Tag
                        color={statusIteams(order?.status)}
                        className="min-w-[110px] text-center"
                      >
                        {order?.status?.replace(/_/g, " ")}
                      </Tag>
                    </td>
                    <td>
                      <div title="View Details">
                        <Button
                          className="font-semibold !border-[#bf2a2c] text-[#bf2a2c] hover:bg-[#bf2a2c] hover:!text-white"
                          onClick={() =>
                            !inMerchant
                              ? navigate(`/orders/${order?.orderId}/details`)
                              : navigate(
                                  `/merchants/${merchantId.merchantId}/order/${order?.orderId}`
                                )
                          }
                        >
                          View
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Pagination */}
      {orderData?.data?.totalPages > 1 && (
        <Pagination
          current={orderData?.data?.currentPageNumber + 1}
          showTotal={(total, limit) =>
            `Showing ${limit[0]} to ${limit[1]} of ${total} items`
          }
          defaultPageSize={limit}
          defaultCurrent={page}
          total={orderData?.data?.totalElements}
          onChange={(page) => setPageNumber(page - 1)}
          className="mt-4"
        />
      )}
      {/* Drawer */}
      <Drawer
        title="Do Settlement"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="mb-3">
          <h2>
            Total Orders:<span className="ml-2">{selectedOrders?.length}</span>
          </h2>
          <h2>
            Total Collected Amount:<span className="ml-2">{totalAmount}</span>
          </h2>
          <h2>
            Total Delivery Charge:
            <span className="ml-2">{totalDeliveryCharge}</span>
          </h2>
          <h2>
            Total Receivable Amount:
            <span className="ml-2">{totalAmount - totalDeliveryCharge}</span>
          </h2>
        </div>
        <Input.TextArea
          defaultValue={selectedOrders?.map((order) => order?.orderId)}
          value={selectedOrders?.map((order) => order?.orderId)}
          name="orderIds"
          className="mb-3"
          disabled
          autoSize={true}
        />
        <Button
          className="bg-blue-500 text-white"
          type="primary"
          onClick={submitSettlement}
        >
          Confirm
        </Button>
      </Drawer>
    </>
  );
};
export default Order;
