import { Alert, Card, Divider, Form, Input, Space } from "antd";
import axios from "axios";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../common/PageTitle";
import MainLayout from "../../Layouts";
import Titleh4 from "../../common/Titleh4";
import SubmitButton from "../../common/SubmitButton";
import { errorNotification, updateNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";

const MerchantEdit = () => {
  const [form] = Form.useForm();
  const token = useSelector((state:any) => state.token.value);
  const route = useParams();

  // Fetch Api Start
  const [merchantData, setMerchantData] = useState<any>({
    loading: false,
    data: [],
  });

  const url = `${process.env.REACT_APP_RIDER_API}/business/${route.merchantId}`;
  const fetchData = useCallback(async () => {
    setMerchantData({ loading: true, data: [] });
    try {
      const response = await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMerchantData({ loading: false, data: res?.data?.business });
        });
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (merchantData) {
      form.resetFields();
    }
  }, [merchantData]);

  // Fetch Api End
  // Form Submit
  const onSubmit = async (data: any) => {
    const readyData = data && {
      id: merchantData?.data?.id,
      codCharge: data.codCharge * 1,
      companyName: data.companyName,
      emailAddress: data.emailAddress,
      mobileNumber: data.mobileNumber,
      ownersName: data.ownersName,
      dhakaSuburbs: {
        charge: data.dhakaSuburbsCharge,
        next: data.dhakaSuburbsNext,
        time: data.dhakaSuburbsTime,
      },
      outsideDhaka: {
        charge: data.outsideDhakaCharge,
        next: data.outsideDhakaNext,
        time: data.outsideDhakaTime,
      },
      insideDhaka: {
        charge: data.insideDhakaCharge,
        next: data.insideDhakaNext,
        time: data.insideDhakaTime,
      },
      sameDayDelivery: {
        charge: data.sameDayDeliveryCharge,
        next: data.sameDayDeliveryNext,
        time: data.sameDayDeliveryTime,
      },
    };

    if (merchantData) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/business`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            updateNotification("Merchant Updated successfully", "success");
            fetchData();
            form.resetFields();
            window.history.back();
          } else {
            errorNotification("something wrong");
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    }
  };
  // End Form Submit

  return (
    <MainLayout>
      <PageTitle title={"Edit Merchant"} />
      <div className="lg:flex">
        {/* Left */}
        <div className="lg:mr-4 lg:w-3/6 xl:w-2/6">
          <Card className="mb-3 shadow rounded-md">
            <Titleh4
              title={
                <p className="capitalize">
                  {merchantData?.data?.companyName?.toUpperCase() ||
                    "Unknown Merchant"}
                </p>
              }
            />
            <Divider />
            <div>
              <Space wrap>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-4 h-4 stroke-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
                <span>
                  {merchantData?.data?.mobileNumber?.replace("+88", "") || ""}
                </span>
              </Space>
            </div>
            <div>
              <Space>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-4 h-4 stroke-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <span>{merchantData?.data?.emailAddress || ""}</span>
              </Space>
            </div>
          </Card>
        </div>
        {/* Right */}
        <div className="basis-full rounded-md">
          <Card className="mb-3 shadow rounded-md">
            <Titleh4 title="Update Merchant" />
            <Divider className="my-3" />
            <Form
              name="control-hooks"
              form={form}
              onFinish={onSubmit}
              layout="vertical"
              autoComplete="off"
              initialValues={{
                ...merchantData?.data,
                dhakaSuburbsCharge: merchantData?.data?.dhakaSuburbs?.charge,
                dhakaSuburbsNext: merchantData?.data?.dhakaSuburbs?.next,
                dhakaSuburbsTime: merchantData?.data?.dhakaSuburbs?.time,
                insideDhakaCharge: merchantData?.data?.insideDhaka?.charge,
                insideDhakaNext: merchantData?.data?.insideDhaka?.next,
                insideDhakaTime: merchantData?.data?.insideDhaka?.time,
                outsideDhakaCharge: merchantData?.data?.outsideDhaka?.charge,
                outsideDhakaNext: merchantData?.data?.outsideDhaka?.next,
                outsideDhakaTime: merchantData?.data?.outsideDhaka?.time,
                sameDayDeliveryCharge:
                  merchantData?.data?.sameDayDelivery?.charge,
                sameDayDeliveryNext: merchantData?.data?.sameDayDelivery?.next,
                sameDayDeliveryTime: merchantData?.data?.sameDayDelivery?.time,
              }}
            >
              <div className="grid grid-cols-2 gap-4 mb-3">
                <Form.Item
                  hasFeedback
                  label="Merchant Name"
                  name="companyName"
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: "Merchant Name is Required!",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Enter Merchant Name" />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  label="Email Address"
                  name="emailAddress"
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: "Email Address is Required!",
                    },
                  ]}
                >
                  <Input type="email" placeholder="Enter Email Address" />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  label="Mobile Number"
                  name="mobileNumber"
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: "Mobile Number is Required!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Enter Mobile Number"
                    disabled
                  />
                </Form.Item>

                {merchantData?.data?.ownersName && (
                  <Form.Item
                    hasFeedback
                    label="Owner Name"
                    name="ownersName"
                    className="m-0"
                  >
                    <Input type="text" placeholder="Enter Owner Name" />
                  </Form.Item>
                )}

                <Form.Item
                  hasFeedback
                  label="COD Charge &#2547;"
                  name="codCharge"
                  className="m-0"
                >
                  <Input type="number" placeholder="Enter COD Charge" />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-3">
                {merchantData?.data?.dhakaSuburbs && (
                  <div>
                    <Divider orientation="left">Dhaka Suburbs</Divider>
                    <Space className="mb-2">
                      <Form.Item
                        hasFeedback
                        label="Charge &#2547;"
                        name="dhakaSuburbsCharge"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Charge" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Next &#2547;"
                        name="dhakaSuburbsNext"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Next" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Time"
                        name="dhakaSuburbsTime"
                        className="m-0"
                      >
                        <Input type="text" placeholder="Enter Time" />
                      </Form.Item>
                    </Space>
                  </div>
                )}
                {merchantData?.data?.insideDhaka && (
                  <div>
                    <Divider orientation="left">Inside Dhaka</Divider>
                    <Space className="mb-2">
                      <Form.Item
                        hasFeedback
                        label="Charge &#2547;"
                        name="insideDhakaCharge"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Charge" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Next &#2547;"
                        name="insideDhakaNext"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Next" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Time"
                        name="insideDhakaTime"
                        className="m-0"
                      >
                        <Input type="text" placeholder="Enter Time" />
                      </Form.Item>
                    </Space>
                  </div>
                )}
                {merchantData?.data?.outsideDhaka && (
                  <div>
                    <Divider orientation="left">Outside Dhaka</Divider>
                    <Space className="mb-2">
                      <Form.Item
                        hasFeedback
                        label="Charge &#2547;"
                        name="outsideDhakaCharge"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Charge" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Next &#2547;"
                        name="outsideDhakaNext"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Next" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Time"
                        name="outsideDhakaTime"
                        className="m-0"
                      >
                        <Input type="text" placeholder="Enter Time" />
                      </Form.Item>
                    </Space>
                  </div>
                )}
                {merchantData?.data?.sameDayDelivery && (
                  <div>
                    <Divider orientation="left">Same Day Delivery</Divider>
                    <Space className="mb-2">
                      <Form.Item
                        hasFeedback
                        label="Charge &#2547;"
                        name="sameDayDeliveryCharge"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Charge" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Next &#2547;"
                        name="sameDayDeliveryNext"
                        className="m-0"
                      >
                        <Input type="number" placeholder="Enter Next" />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        label="Time"
                        name="sameDayDeliveryTime"
                        className="m-0"
                      >
                        <Input type="text" placeholder="Enter Time" />
                      </Form.Item>
                    </Space>
                  </div>
                )}
              </div>
              <SubmitButton
                className="!text-white bg-red-600 hover:!bg-red-500 border-none"
                buttonText="Update Merchant"
              />
            </Form>
          </Card>
        </div>
      </div>
    </MainLayout>
  );
};
export default MerchantEdit;
