import React, { ReactElement, useCallback, useEffect, useState } from "react";
import MainLayout from "../Layouts";
import PageTitle from "../common/PageTitle";
import {
  Avatar,
  Button,
  Pagination,
  Image,
  Space,
  AutoComplete,
  Input,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Loader from "../common/Loader";
import { useSelector } from "react-redux";

const Merchants = (): ReactElement => {
  const navigate = useNavigate();
  // Pagination
  const [page, setPageNumber] = useState<any>(0);
  const [limit, setLimit] = useState<any>(16);
  const [nameOrMobileNumber, setNameOrMobileNumber] = useState<any>();

  // All Merchant API START
  const token = useSelector((state:any) => state.token.value);
  const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
  const [merchantData, setMerchantData] = useState<any>({
    loading: false,
    data: [],
  });

  const [merchantFilter, setMerchantFilter] = useState<any>({
    loading: false,
    list: [],
  });
  const url = `${encodedUrl}/business/all?page=${page}&limit=${limit}`;

  const fetchMerchantData = useCallback(async () => {
    setMerchantData({ loading: true, data: [] });
    try {
      const response = await axios
        .get(
          url +
            (nameOrMobileNumber
              ? `&nameOrMobileNumber=${nameOrMobileNumber}`
              : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setMerchantData({ loading: false, data: res?.data });
        });
    } catch (error) {
      console.error(error.message);
    }
  }, [page, limit, nameOrMobileNumber]);

  useEffect(() => {
    fetchMerchantData();
  }, [page, limit, nameOrMobileNumber]);

  // Filter by Merchant Api
  const getMerchantOption = useCallback(async (val) => {
    setMerchantFilter({ loading: true, list: [] });
    try {
      const response = await axios
        .get(url + (val ? `&nameOrMobileNumber=${val}` : ``), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMerchantFilter({
            loading: false,
            list: res.data?.businesses?.map((data) => ({
              label: `${data.companyName?.toUpperCase()}`,
              value: data?.mobileNumber?.replace("+88", ""),
            })),
          });
        });
    } catch (error) {
      setMerchantFilter({ loading: false, list: [] });
      console.error(error.message);
    }
  }, []);
  useEffect(() => {
    getMerchantOption(``);
  }, [getMerchantOption]);

  return (
    <MainLayout>
      <PageTitle
        title={"Merchant List"}
        subTitle={`(${merchantData.data?.totalElements})`}
        extra={
          <Space wrap>
            <AutoComplete
              options={merchantFilter?.list}
              onSelect={(val) => setNameOrMobileNumber(val)}
              onChange={(e) => setNameOrMobileNumber(e)}
              onSearch={(val) => getMerchantOption(val)}
              filterOption={true}
            >
              <Input.Search
                placeholder="Filter by Merchant"
                className="icon-btn"
              />
            </AutoComplete>
            <Button
              className="!bg-[#bf2a2c] hover:!bg-red-600"
              type="primary"
              onClick={() => setNameOrMobileNumber(null)}
            >
              Reset
            </Button>
          </Space>
        }
      />
      {merchantData?.loading ? (
        <Loader />
      ) : (
        <div className="table-responsive">
          <table className="table-auto dataTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email Address</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {merchantData?.data?.businesses?.length ? (
                merchantData?.data?.businesses?.map((data: any, index: any) => (
                  <tr
                    className="hover:bg-slate-50 cursor-pointer transition ease-in-out"
                    onClick={() => navigate(`/merchants/${data.id}/details`)}
                    key={index}
                  >
                    <td>
                      <Space wrap>
                        <div className="mr-2">
                          {data?.image ? (
                            <Avatar src={<Image src={data?.image} />} />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </div>
                        <div>
                          <h2 className="text-black font-semibold uppercase">
                            {data?.companyName || "No Name"}
                          </h2>
                        </div>
                      </Space>
                    </td>
                    <td>
                      <p className="text-black">
                        {data?.mobileNumber?.replace("+88", "") || "-"}
                      </p>
                    </td>
                    <td>{data?.emailAddress || "-"}</td>

                    <td>
                      <p>
                        {data.createdAt
                          ? moment(data.createdAt).format("LL")
                          : "-"}
                      </p>
                    </td>
                    <td>
                      <Space wrap>
                        <div title="View Details">
                          <Button className="font-semibold !border-cyan-600 text-cyan-600 hover:bg-cyan-600 hover:!text-white">
                            View
                          </Button>
                        </div>
                        <div title="Edit">
                          <Button
                            className="font-semibold !border-[#bf2a2c] text-[#bf2a2c] hover:bg-[#bf2a2c] hover:!text-white"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigate(`/merchants/${data.id}/edit`);
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                        {/* <div title="View Details">
                          <Button
                            // shape="circle"
                            className="bg-transparent border-dashed"
                          >
                            <FileDoneOutlined />
                            View
                          </Button>
                        </div> */}
                        {/* <div title="Edit">
                          <Button
                            // shape="circle"
                            className="bg-transparent border-dashed"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigate(`/merchants/${data.id}/edit`);
                            }}
                          >
                            <EditOutlined />
                          </Button>
                        </div> */}
                      </Space>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Pagination */}
      {merchantData?.data?.totalPages > 1 && (
        <Pagination
          current={merchantData?.data?.currentPageNumber + 1}
          showTotal={(total, limit) =>
            `Showing ${limit[0]} to ${limit[1]} of ${total} items`
          }
          defaultPageSize={limit}
          defaultCurrent={page}
          total={merchantData?.data?.totalElements}
          onChange={(page) => setPageNumber(page - 1)}
          className="mt-4"
        />
      )}
    </MainLayout>
  );
};
export default Merchants;
