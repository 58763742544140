import * as React from "react";

const Loader = () => {
  return (
    <div className="preloader">
      <div className="loader">
        <div className="inner_loader"></div>
        <div className="inner_loader"></div>
        <div className="inner_loader"></div>
        <div className="inner_loader"></div>
      </div>
    </div>
  );
};
export default Loader;
