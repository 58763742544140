import React, { ReactElement } from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import MainHeader from "./Header";
import Sidebar from "./Sidebar";
import MainFooter from "./Footer";

const MainLayout = ({ children }: { children: any }) => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <Layout>
        <MainHeader />
        <Content className="main-content"
          style={{
            margin: "16px",
          }}
        >
          {children}
        </Content>
        <MainFooter />
      </Layout>
    </Layout>
  );
};
export default MainLayout;
