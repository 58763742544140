import React, { ReactElement, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import {
  HomeOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import Logo from "../../../images/logo.svg";
import SmallLogo from "../../../images/favicon.png";

const Sidebar = (): ReactElement => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      {/* Logo */}
      <div className="logo bg-[#0d2736] mb-3">
        <Link to="/">
          <img
            src={collapsed ? SmallLogo : Logo}
            alt="Logo"
            className="logoImg h-16 p-2 m-auto"
          />
        </Link>
      </div>

      {/* Menu Items */}
      <Menu
        theme="dark"
        defaultSelectedKeys={["0"]}
        mode="inline"
        className="side-menu"
      >
        <Menu.Item
          icon={<HomeOutlined />}
          key={"Dashboard"}
          className={splitLocation[1] === "" ? "active" : ""}
        >
          <Link to="/">Dashboard</Link>
        </Menu.Item>
        <Menu.Item
          icon={<ShoppingCartOutlined />}
          key={"Order"}
          className={splitLocation[1] === "orders" ? "active" : ""}
        >
          <Link to="/orders">Order</Link>
        </Menu.Item>
        <Menu.Item
          icon={<ShoppingOutlined />}
          key={"Merchants"}
          className={splitLocation[1] === "merchants" ? "active" : ""}
        >
          <Link to="/merchants">Merchants</Link>
        </Menu.Item>
        <Menu.Item
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
              />
            </svg>
          }
          key={"invoices"}
          className={splitLocation[1] === "invoices" ? "active" : ""}
        >
          <Link to="/invoices">Invoices</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
export default Sidebar;
