import React, { ReactElement, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Steps,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import { SwapOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { errorNotification, responseNotification } from "../../../utils/notify";
import MainLayout from "../../Layouts";
import PageTitle from "../../common/PageTitle";
import { statusArray } from "../../../utils/status";
import SubmitButton from "../../common/SubmitButton";
import OrderContent from "./content";
import Titleh4 from "../../common/Titleh4";
import TextArea from "antd/es/input/TextArea";

const OrderDetail = (): ReactElement => {
  // Status Update States
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<any>();
  const [statusData, setStatusData] = useState<any>();
  const [inTransit, setInTransit] = useState<boolean>(false);
  const orderData = useSelector((state: any) => state.order.value);

  const token = useSelector((state: any) => state.token.value);

  // Status Update Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onStatusChange = async (val: any) => {
    const readyData = val && {
      orderId: orderData?.orderId,
      currentAddress: val?.currentAddress,
      note: val?.note,
      status: val?.status,
    };

    await fetch(`${process.env.REACT_APP_ORDER_API}/admin/order`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 200) {
          responseNotification("Status Updated Successfully", "success");
          form.resetFields();
          closeModal();
        } else {
          errorNotification(res.message);
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  // Extra
  // useEffect(() => {
  //   if (orderData?.data) {
  //     setStatusData(
  //       orderData.data?.statusLogs?.map((data) => ({
  //         title: data?.value,
  //         description: data?.note,
  //       }))
  //     );
  //   }
  // }, []);

  return (
    <MainLayout>
      <PageTitle
        title={"Order Details"}
        extra={
          <div title="Change Status">
            <Button
              className="!text-white !bg-[#bf2a2c] hover:!bg-red-600 border-none"
              onClick={showModal}
            >
              <SwapOutlined />
            </Button>
          </div>
        }
      />
      {/* Content */}
      <div className="lg:flex">
        {/* left */}
        <div className="lg:mr-4 lg:w-3/6 xl:w-2/6">
          <Card className="mb-3 shadow rounded-md">
            <Titleh4
              title={
                <div className="flex items-center">
                  <Avatar icon={<UserOutlined />} className="mr-3" />
                  <h6 className="capitalize">
                    {orderData?.customer?.name || "Unknown Merchant"}
                  </h6>
                </div>
              }
            />
            <Divider />
            <div>
              <Space wrap>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-4 h-4 stroke-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
                <span>
                  {orderData?.customer?.mobileNumber?.replace("+88", "")}
                </span>
              </Space>
            </div>
            <div>
              <Space wrap>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-4 h-4 stroke-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                  />
                </svg>
                <span>Total Amount: {Math.round(orderData?.total) || 0}</span>
              </Space>
            </div>
            <div>
              <Space>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-4 h-4 stroke-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                  />
                </svg>

                <span>Total Paid: </span>
                <span> {Math.round(orderData?.totalPaid) || 0}</span>
              </Space>
            </div>
          </Card>

          {/* Status Logs */}
          <Card className="mb-3 shadow rounded-md">
            <div>
              <Titleh4 title="Status Logs" />
              <Divider />
              {/*<Steps
                responsive={true}
                direction="vertical"
                size="small"
                current={statusArray
                  .map((e) => e.value)
                  .indexOf(orderData?.data?.status)}
                items={statusData}
              /> */}
              <Steps
                responsive={true}
                direction="vertical"
                size="small"
                current={statusArray
                  .map((value) => value)
                  .indexOf(orderData?.status)}
                status={orderData?.status === "CANCELLED" ? "error" : "process"}
                items={statusArray.map((data) => ({
                  title: data?.split("_")?.join(" "),
                  description:
                    data === "IN_TRANSIT" &&
                    orderData?.statusLogs?.at(-1).currentAddress,
                }))}
              />
            </div>
          </Card>
        </div>
        {/* right */}
        <div className="basis-full rounded-md">
          <Card className="mb-3 shadow rounded-md">
            <OrderContent refetch={!isModalOpen} />
          </Card>
        </div>
      </div>

      {/* Modal */}
      <Modal
        title="Change Order Status"
        open={isModalOpen}
        onCancel={closeModal}
        footer=""
      >
        <Form layout="vertical" onFinish={onStatusChange}>
          <Form.Item
            hasFeedback
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "Status is required",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select Status"
              onChange={(value) => {
                value === "IN_TRANSIT"
                  ? setInTransit(true)
                  : setInTransit(false);
                setStatus(value);
              }}
              onSearch={(value) => setStatus(value)}
              filterOption={true}
              options={statusArray?.map((data) => ({
                label: data?.split("_").join(" "),
                value: data,
              }))}
            ></Select>
          </Form.Item>
          {inTransit && (
            <Form.Item
              hasFeedback
              label="Current Location"
              name="currentAddress"
            >
              <Input placeholder="Enter your notes" />
            </Form.Item>
          )}

          <Form.Item hasFeedback label="Notes" name="note">
            <TextArea rows={4} placeholder="Enter your notes" />
          </Form.Item>
          <SubmitButton
            className="!text-white !bg-[#bf2a2c] hover:!bg-red-600 border-none"
            buttonText="Update"
          />
        </Form>
      </Modal>
    </MainLayout>
  );
};
export default OrderDetail;
