import React, { ReactElement } from "react";
import Order from "..";
import MainLayout from "../../Layouts";

const OrderList = (): ReactElement => {
  return (
    <MainLayout>
      <Order />
    </MainLayout>
  );
};
export default OrderList;
