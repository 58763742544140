import { AutoComplete, Button, Input, Pagination, Space, Tag } from "antd";
import axios from "axios";
import moment from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MerchantInvoice from "../Invoice";
import PageTitle from "../../common/PageTitle";

const InvoiceList = ({ fromMerchant }: { fromMerchant?: boolean }) => {
  const token = useSelector((state: any) => state.token.value);
  const merchantData = useSelector((state: any) => state.merchant.value);
  const merchantId = useParams().merchantId;
  const [page, setPageNumber] = useState<any>(0);
  const [limit, setLimit] = useState<any>(20);
  const [invoiceId, setInvoiceId] = useState<any>();
  const [merchantInfo, setMerchantInfo] = useState<any>(merchantId);

  // API START
  const url = `${process.env.REACT_APP_ORDER_API}/invoice/by-merchant?page=${page}&limit=${limit}`;

  const [invoices, setInvoices] = useState<any>({
    loading: false,
    data: [],
  });

  const fetchSettledOrder = useCallback(async () => {
    setInvoices({ loading: true, data: [] });
    try {
      const response = await axios
        .get(
          url +
            (invoiceId ? `&invoiceId=${invoiceId}` : ``) +
            (merchantInfo ? `&merchantId=${merchantInfo}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setInvoices({ loading: false, data: res?.data });
        });
    } catch (error) {
      console.error(error.message);
    }
  }, [page, limit, invoiceId, merchantInfo]);

  useEffect(() => {
    fetchSettledOrder();
  }, [page, limit, invoiceId, merchantInfo]);

  // Filter by Invoice Api
  const [invoiceFilter, setInvoiceFilter] = useState<any>({
    loading: false,
    list: [],
  });

  const getInvoiceOption = useCallback(async (val) => {
    setInvoiceFilter({ loading: true, list: [] });
    try {
      const response = await axios
        .get(
          url +
            (invoiceId ? `&invoiceId=${invoiceId}` : ``) +
            (merchantInfo ? `&merchantId=${merchantInfo}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setInvoiceFilter({
            loading: false,
            list: res.data?.invoices?.map((data) => ({
              label: `${data.invoiceId}`,
              value: data?.invoiceId,
            })),
          });
        });
    } catch (error) {
      setInvoiceFilter({ loading: false, list: [] });
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    getInvoiceOption(``);
  }, [getInvoiceOption]);

  // Filter by Merchant Api
  const merchantUrl = `${process.env.REACT_APP_RIDER_API}/business/all?page=${page}&limit=${limit}`;
  const [merchantFilter, setMerchantFilter] = useState<any>({
    loading: false,
    list: [],
  });

  const getMerchantOption = useCallback(async (val) => {
    setMerchantFilter({ loading: true, list: [] });
    try {
      const response = await axios
        .get(merchantUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMerchantFilter({
            loading: false,
            list: res.data?.businesses?.map((data) => ({
              label: `${data.companyName?.toUpperCase()}`,
              value: data?.id,
            })),
          });
        });
    } catch (error) {
      setMerchantFilter({ loading: false, list: [] });
      console.error(error.message);
    }
  }, []);
  useEffect(() => {
    getMerchantOption(``);
  }, [getMerchantOption]);

  return (
    <>
      <PageTitle
        title={"Settlement History"}
        subTitle={`(${invoices?.data?.totalElements})`}
        extra={
          <Space wrap>
            <AutoComplete
              options={invoiceFilter?.list}
              onSelect={(val) => setInvoiceId(val)}
              onChange={(e) => setInvoiceId(e)}
              onSearch={(val) => getInvoiceOption(val)}
              filterOption={true}
            >
              <Input.Search
                placeholder="Filter by Invoice ID"
                className="icon-btn"
              />
            </AutoComplete>
            {!fromMerchant && (
              <AutoComplete
                options={merchantFilter?.list}
                onSelect={(val) => setMerchantInfo(val)}
                onChange={(e) => setMerchantInfo(e)}
                onSearch={(val) => getMerchantOption(val)}
                filterOption={true}
              >
                <Input.Search
                  placeholder="Filter by Merchant"
                  className="icon-btn"
                />
              </AutoComplete>
            )}

            <Button
              className="!bg-[#bf2a2c] hover:!bg-red-600"
              type="primary"
              onClick={() => {
                setInvoiceId(null);
                setMerchantInfo(merchantId);
              }}
            >
              Reset
            </Button>
          </Space>
        }
      />

      {invoices?.loading ? (
        <Loader />
      ) : (
        <div className="table-responsive">
          <table className="table-auto dataTable">
            <thead>
              <tr>
                <th>Invoice ID</th>
                <th>Invoice Date</th>
                <th>Total Orders</th>
                <th>Collected Amount</th>
                <th>Delivery Charge</th>
                <th>Receivable Amount</th>
                <th>Status</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {invoices?.data?.invoices?.length ? (
                invoices?.data?.invoices?.map((data: any, index: any) => (
                  <tr
                    className="hover:bg-slate-50 cursor-pointer transition ease-in-out"
                    key={index}
                  >
                    <td>
                      {/* {data?.orderIds} */}
                      {data?.invoiceId}
                    </td>
                    <td>
                      <p>{moment(data?.createdAt).format("LL")}</p>
                    </td>
                    <td>{data?.orderIds?.length}</td>
                    <td>&#2547; {data?.collectedAmount}</td>

                    <td>&#2547; {Math.round(data?.fee)}</td>
                    <td>
                      <p
                        className={
                          data?.receivable < 0 ? "text-[#CD4C4C]" : "text-black"
                        }
                      >
                        &#2547;
                        {Math.abs(Math.round(data?.receivable))}
                      </p>
                    </td>
                    <td>
                      <Tag color="#006666" className="uppercase">
                        Paid
                      </Tag>
                    </td>
                    <td>
                      <div title="Download Invoice">
                        <PDFDownloadLink
                          document={
                            <MerchantInvoice
                              invoicData={data}
                              merchantData={merchantData}
                              token={token}
                            />
                          }
                          fileName={`Invoice ${data?.invoiceId?.replace(
                            "D-I-",
                            ""
                          )}`}
                        >
                          <Button className="font-semibold !border-[#bf2a2c] text-[#bf2a2c] hover:bg-[#bf2a2c] hover:!text-white">
                            <Space>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                                />
                              </svg>

                              <span>PDF</span>
                            </Space>
                          </Button>
                        </PDFDownloadLink>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Pagination */}
      {invoices?.data?.totalPages > 1 && (
        <Pagination
          current={invoices?.data?.currentPageNumber + 1}
          showTotal={(total, limit) =>
            `Showing ${limit[0]} to ${limit[1]} of ${total} items`
          }
          defaultPageSize={limit}
          defaultCurrent={page}
          total={invoices?.data?.totalElements}
          onChange={(page) => setPageNumber(page - 1)}
          className="mt-4"
        />
      )}
    </>
  );
};
export default InvoiceList;
