import { PageHeader } from "@ant-design/pro-layout";
import React, { ReactElement } from "react";

const PageTitle = ({
  title,
  subTitle,
  extra,
}: {
  title: any;
  subTitle?: string;
  extra?: ReactElement;
}): ReactElement => {
  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={title}
      subTitle={subTitle}
      extra={extra}
    />
  );
};
export default PageTitle;
