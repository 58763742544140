import { notification } from 'antd'

export const responseNotification = (message:any, type?:string) => {
  notification[type === 'warning' || type === 'success' || type === 'error' ? type : 'success']({
    message: message?.code === 200 ? 'Operation successful' : message
  })
}
export const updateNotification = (message:any, type?:string) => {
  notification[type === 'warning' || type === 'success' || type === 'error' ? type : 'success']({
    message: message?.code === 200 ? 'Updated successfully' : message
  })
}

export const saveSuccessNotification = (Model:string) => {
  notification.success({
    message: `${Model}`,
  })
}


export const errorNotification = (errorMessage:string) => {
  notification.error({
    message: `${errorMessage}!!`,
  })
}
export const customErrorNotification = (errorMessage:string) => {
  notification.error({
    message: errorMessage,
  })
}