import React, { ReactElement } from "react";
import { Footer } from "antd/es/layout/layout";

const MainFooter = (): ReactElement => {
  return (
    <Footer className="text-center !bg-white drop-shadow-md">
      <span className="text-rose-800">Jachai Limited</span> Copyright &copy;{" "}
      {new Date().getFullYear()}
    </Footer>
  );
};
export default MainFooter;
