import { Header } from "antd/es/layout/layout";
import React, { ReactElement, useState } from "react";
import { Menu } from "@headlessui/react";
import { Avatar, Form, Input, Modal, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../../common/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import { logout } from "../../../redux/state/login/loginSlice";
import { setToken } from "../../../redux/state/token/tokenSlice";

const MainHeader = (): ReactElement => {
  // Redux State
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.value);
  const token = useSelector((state: any) => state.token.value);

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // Logout
  const Logout = () => {
    dispatch(logout());
    dispatch(setToken(""));
    navigate("/");
  };
  // Change Password
  const onSubmit = async (data: any) => {
    const readyData = data && {
      email: data?.email,
      newPassword: data?.newPassword,
      oldPassword: data?.oldPassword,
      cPassword: data?.cPassword,
    };
    await fetch(`${process.env.REACT_APP_AUTH_API}/admin/password/update`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (data?.newPassword === data?.cPassword) {
          if (res.statusCode === 200) {
            form.resetFields();
            responseNotification("Password Updated Successfully", "success");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  return (
    <Header className="!bg-white drop-shadow-md py-3 z-40">
      <Menu
        as="div"
        className="relative inline-block text-left top-1/2 -translate-y-1/2 float-right "
      >
        <div>
          <Menu.Button className="inline-flex w-full justify-between items-center border rounded-full bg-slate-200 text-left text-lg font-medium transition-all text-black hover:bg-slate-100">
            <span className="mr-2 px-4 py-2 text-[16px]">{user?.name}</span>
            <Avatar
              size={45}
              icon={<UserOutlined />}
              src={
                user?.profilePicture ||
                "https://t3.ftcdn.net/jpg/00/64/67/80/360_F_64678017_zUpiZFjj04cnLri7oADnyMH0XBYyQghG.jpg"
              }
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                <div className="text-center">
                  <Avatar
                    size={100}
                    icon={<UserOutlined />}
                    src={
                      user?.profilePicture ||
                      "https://t3.ftcdn.net/jpg/00/64/67/80/360_F_64678017_zUpiZFjj04cnLri7oADnyMH0XBYyQghG.jpg"
                    }
                  />
                  <p className="m-0 leading-normal capitalize mt-3 font-bold">
                    {user?.name}
                  </p>
                  <p className="m-0 leading-normal">{user?.email}</p>
                  <hr className="my-3" />
                </div>
              </Menu.Item>
              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-red-600 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={showModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 mr-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>
                    Change Password
                  </button>
                )}
              </Menu.Item> */}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={Logout}
                    className={`${
                      active ? "bg-[#bf2a2c] text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                      />
                    </svg>
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {/* Modal */}
      <Modal
        title="Change Password"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email address!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Please enter your old password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please enter your new password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="cPassword"
            rules={[
              {
                required: true,
                message: "Please re-enter your new password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div>
            <SubmitButton
              className="!text-white bg-red-600 hover:!bg-red-500 border-none"
              buttonText="Change"
            />
          </div>
        </Form>
      </Modal>
    </Header>
  );
};
export default MainHeader;
