import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginReducer from "../state/login/loginSlice";
import tokenReducer from "../state/token/tokenSlice";
import merchantReducer from "../state/merchant/merchantSlice";
import merchantCountReducer from "../state/merchant/merchantCountSlice";
import orderReducer from "../state/order/orderSlice";

const persistConfig = {
  key: "root",
  storage,
};

// connect state reducers with createSlice name & whole or custom slice name
const reducer = combineReducers({
  user: loginReducer,
  token: tokenReducer,
  merchant: merchantReducer,
  order: orderReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
