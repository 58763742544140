import React, { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Merchants from "../components/Merchants";
import Login from "../components/Login";
import MerchantDetails from "../components/Merchants/Details";
import MerchantEdit from "../components/Merchants/Edit";
import MerchantOrder from "../components/Merchants/Order";
import { useSelector } from "react-redux";
import MerchantSettledList from "../components/Merchants/Settled";
import MerchantUnsettledList from "../components/Merchants/Unsettled";
import MerchantOrderDetails from "../components/Merchants/OrderDetails";
import MerchantInvoice from "../components/Merchants/Invoice";
import Invoices from "../components/Invoices";
import OrderList from "../components/Order/OrderList";
import OrderDetail from "../components/Order/Details";

const MainRoutes = (): ReactElement => {
  const token = useSelector((state:any) => state.token.value);

  return (
    <BrowserRouter>
      <Routes>
        {!token ? (
          <>
            <Route path="*" element={<Login />} />
            <Route path="/" element={<Login />} />
          </>
        ) : (
          <>
            <Route path="*" element={<Dashboard />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="merchants" element={<Merchants />} />
            <Route
              path="merchants/:merchantId/details"
              element={<MerchantDetails />}
            />
            <Route
              path="merchants/:merchantId/unsettled"
              element={<MerchantUnsettledList />}
            />
            <Route
              path="merchants/:merchantId/settled"
              element={<MerchantSettledList />}
            />
            <Route
              path="merchants/:merchantId/edit"
              element={<MerchantEdit />}
            />
            <Route
              path="merchants/:merchantId/order"
              element={<MerchantOrder />}
            />
            <Route
              path="merchants/:merchantId/order/:orderId"
              element={<MerchantOrderDetails />}
            />
            <Route
              path="merchants/:merchantId/invoice"
              element={<MerchantInvoice />}
            />
            <Route path="orders" element={<OrderList />} />
            <Route path="orders/:orderId/details" element={<OrderDetail />} />
            <Route path="invoices" element={<Invoices />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MainRoutes;
