import * as React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../Layouts";
import Order from "../../Order";
import MerchantLayout from "../Layout";

const MerchantUnsettledList = () => {
  const shopID = useParams();

  return (
    <MainLayout>
      <MerchantLayout>
        <Order
          shopID={shopID.merchantId}
          inMerchant={true}
          paidStatus="UNPAID"
          productStatus="DELIVERED"
        />
      </MerchantLayout>
    </MainLayout>
  );
};
export default MerchantUnsettledList;
