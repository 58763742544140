import { Button } from "antd";
import React, { ReactElement } from "react";

const SubmitButton = ({
  className,
  buttonText,
}: {
  className: string;
  buttonText: string;
}): ReactElement => {
  return (
    <Button htmlType="submit" className={className}>
      {buttonText}
    </Button>
  );
};
export default SubmitButton;
