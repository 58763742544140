export const statusArray = [
  "INITIATED",
  "READY_TO_PICKUP",
  "PICKED",
  "IN_TRANSIT",
  "DELIVERED",
  "CANCELLED",
];

export const statusIteams = (menu: string) => {
  switch (menu) {
    case "INITIATED":
      return "#fb923c";
    case "READY_TO_PICKUP":
      return "#3366cc";
    case "PICKED":
      return "#0000ff";
    case "IN_TRANSIT":
      return "#cc66ff";
    case "DELIVERED":
      return "#006666";
    case "CANCELLED":
      return "#bf2a2c";
    default:
      return "#87d068";
  }
};
