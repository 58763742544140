import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Divider } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Titleh4 from "../../common/Titleh4";
import { useParams } from "react-router-dom";
import axios from "axios";
import { setOrder } from "../../../redux/state/order/orderSlice";

const OrderContent = ({
  refetch = false,
}: {
  refetch: boolean;
}): ReactElement => {
  // Order Details API Start
  const token = useSelector((state: any) => state.token.value);
  const route = useParams();
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: [],
  });

  const url = `${process.env.REACT_APP_ORDER_API}/admin/order/details?orderId=${route.orderId}`;
  const fetchOrderData = useCallback(async () => {
    setOrderData({ loading: true, data: [] });
    try {
      const response = await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setOrderData({ loading: false, data: res?.data?.order });
          dispatch(setOrder(res?.data?.order));
        });
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    fetchOrderData();
  }, []);

  useEffect(() => {
    if (refetch) {
      fetchOrderData();
    }
  }, [refetch]);

  return (
    <>
      <Titleh4 title="Order Details" />
      <Divider />
      <div className="grid grid-cols-3 gap-4">
        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">Order Id</h2>
          <p>{orderData?.data?.orderId || "-"}</p>
        </div>

        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">Created At</h2>
          <p>{moment(orderData?.data?.createdAt).format("L")}</p>
        </div>

        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">
            Payment Method
          </h2>
          <p>{orderData?.data?.paymentMethod || "-"}</p>
        </div>
        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">
            Payment Status
          </h2>
          <p>{orderData?.data?.paymentStatus || "-"}</p>
        </div>

        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">
            Delivery Charge
          </h2>
          <p>&#2547; {Math.round(orderData?.data?.deliveryCharge) || 0}</p>
        </div>

        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">Total</h2>
          <p>&#2547; {Math.round(orderData?.data?.total) || 0}</p>
        </div>
        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">Total Paid</h2>
          <p>&#2547; {Math.round(orderData?.data?.totalPaid) || 0}</p>
        </div>
        <div className="m-0">
          <h2 className="text-black font-semibold capitalize">Due Amount</h2>
          <p>&#2547; {orderData?.data?.dueAmount || 0}</p>
        </div>
        {orderData?.data?.parcelOrder?.specialInstruction && (
          <div className="m-0">
            <h2 className="text-black font-semibold capitalize">
              Special Instruction
            </h2>
            <p className="capitalize">
              {orderData?.data?.parcelOrder?.specialInstruction}
            </p>
          </div>
        )}
        {orderData?.data?.shippingAddress && (
          <div className="m-0">
            <h2 className="text-black font-semibold capitalize">
              Shipping Address
            </h2>
            <p className="capitalize">{orderData?.data?.shippingAddress}</p>
          </div>
        )}
        {orderData?.data?.statusLogs && (
          <>
            <div className="m-0">
              <h2 className="text-black font-semibold capitalize">Status</h2>
              <p className="capitalize">
                {orderData?.data?.statusLogs
                  ?.at(-1)
                  ?.value?.split("_")
                  .join(" ")}
              </p>
            </div>
            <div className="m-0">
              <h2 className="text-black font-semibold capitalize">
                Admin Note
              </h2>
              <p className="capitalize">
                {orderData?.data?.statusLogs?.at(-1).note || "-"}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default OrderContent;
