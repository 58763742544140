import React, { ReactElement } from "react";
import { Button, Card, Divider, List, Space, Avatar } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../common/PageTitle";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Titleh4 from "../../common/Titleh4";

const MerchantLayout = ({ children }: { children?: any }): ReactElement => {
  const navigate = useNavigate();
  const merchantData = useSelector((state:any) => state.merchant.value);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
      <PageTitle
        title={"Merchant Details"}
        extra={
          <div title="Edit Merchant">
            <Button
              className="!text-white bg-[#bf2a2c] hover:!bg-red-600 border-none"
              onClick={(e) => {
                navigate(`/merchants/${merchantData.id}/edit`);
              }}
            >
              <EditOutlined />
            </Button>
          </div>
        }
      />
      <div className="lg:flex">
        {/* Left */}
        <div className="lg:mr-4 lg:w-3/6 xl:w-2/6">
          <Card className="mb-3 shadow rounded-md">
            <Titleh4
              title={
                <div className="flex items-center">
                  <Avatar icon={<UserOutlined />} className="mr-3" />
                  <h6 className="capitalize">
                    {merchantData?.companyName || "Unknown Merchant"}
                  </h6>
                </div>
              }
            />
            <Divider />
            <div>
              <Space wrap>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-4 h-4 stroke-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
                <span>
                  {merchantData?.mobileNumber?.replace("+88", "") || ""}
                </span>
              </Space>
            </div>
            <div>
              <Space>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-4 h-4 stroke-green-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <span>{merchantData?.emailAddress || ""}</span>
              </Space>
            </div>
          </Card>
          <Card className="mb-3 shadow rounded-md">
            <List size="small" className="merchant-menu">
              <List.Item>
                <a
                  href={`/merchants/${merchantData.id}/details`}
                  className={splitLocation[3] === "details" ? "active" : ""}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
                    />
                  </svg>
                  <span className="basis-full">Basic Information</span>
                </a>
              </List.Item>
              <List.Item>
                <a
                  href={`/merchants/${merchantData.id}/unsettled`}
                  className={splitLocation[3] === "unsettled" ? "active" : ""}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                    />
                  </svg>
                  <span>Unsettled Order</span>
                </a>
              </List.Item>
              <List.Item>
                <a
                  href={`/merchants/${merchantData.id}/settled`}
                  className={splitLocation[3] === "settled" ? "active" : ""}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                    />
                  </svg>
                  <span>Settled Order</span>
                </a>
              </List.Item>
              <List.Item>
                <a
                  href={`/merchants/${merchantData.id}/order`}
                  className={splitLocation[3] === "order" ? "active" : ""}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                    />
                  </svg>
                  <span>All Order</span>
                </a>
              </List.Item>
            </List>
          </Card>
        </div>
        {/* Right */}
        <div className="basis-full rounded-md">
          <Card className="mb-3 shadow rounded-md">{children}</Card>
        </div>
      </div>
    </>
  );
};
export default MerchantLayout;
