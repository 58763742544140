import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import * as React from "react";
import Logo from "../../../images/logo.png";
import EmailLogo from "../../../images/gmail.png";
import DialLogo from "../../../images/call.png";
import WebLogo from "../../../images/web.png";
import InvoiceLogo from "../../../images/invoice.png";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  section: {
    flexGrow: 1,
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
  },
  headerLeft: {
    textAlign: "center",
  },
  logo: {
    width: 125,
    marginBottom: "10px",
  },
  icon: {
    width: "16px",
    textAlign: "center",
    marginBottom: "6px",
  },
  shopName: {
    fontSize: 12,
    textTransform: "capitalize",
    color: "#bf2a2c",
    marginBottom: "4px",
    fontWeight: 400,
  },
  address: {
    fontSize: 10,
    color: "#666",
    fontWeight: 400,
    marginBottom: "4px",
  },
  name: {
    fontSize: 12,
    color: "#091328",
    marginBottom: "8px",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  dateText: {
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 300,
    textAlign: "right",
    maxWidth: 200,
    color: "#535353",
  },
  titleArea: {
    backgroundColor: "#E5E5E5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
    marginBottom: 10,
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
  },
  middleSection: {
    width: "100%",
    height: "80%",
  },
  acountAreaPara: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    padding: "0px 5px",
  },
  //footer style

  footerArea: {
    textAlign: "left",
    width: "100%",
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
    borderTop: "1px solid #d5d5d5",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 200,
    marginTop: 5,
  },
  //new end
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 2,
  },
  hr: {
    backgroundColor: "#d5d5d5",
    width: "100%",
    height: "1px",
    margin: "16px 0",
  },
  // table
  invoiceArea: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  invoiceRight: {
    flexGrow: 2,
    border: "1px solid #d5d5d5",
    borderRadius: "3px",
  },
  summaryTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  tableLeft: {
    width: "40%",
    borderRight: "1px solid #d5d5d5",
  },
  tableRight: {
    width: "60%",
  },
  tr: {
    borderBottom: "1px solid #d5d5d5",
  },
  th: {
    fontSize: "12px",
    fontWeight: 700,
    padding: "8px",
  },
  td: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#666",
    padding: "8px",
    whiteSpace: "wrap",
  },
});
const MerchantInvoice = (data: any) => {
  // API START
  const url = `${process.env.REACT_APP_ORDER_API}/order/order-by-invoice?invoiceId=${data?.invoicData?.invoiceId}`;
  const token = data?.token;

  const [order, setOrder] = useState<any>({
    loading: false,
    data: [],
  });

  const fetchOrderDetail = useCallback(async () => {
    setOrder({ loading: true, data: [] });
    try {
      const response = await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setOrder({ loading: false, data: res?.data?.orders });
        });
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    fetchOrderDetail();
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerArea}>
            <View>
              <Image src={Logo} style={styles.logo} />
              <Text style={styles.address}>House: 26, Road: 7, Block: C</Text>
              <Text style={styles.address}>
                Niketon, Gulshan -1, Dhaka-1212
              </Text>
            </View>
            <View style={styles.headerLeft}>
              <Image src={EmailLogo} style={styles.icon} />
              <Text style={styles.address}>support@jachai.com</Text>
            </View>
            <View style={styles.headerLeft}>
              <Image src={DialLogo} style={styles.icon} />
              <Text style={styles.address}>9639-333444</Text>
            </View>
            <View>
              <Image src={WebLogo} style={styles.icon} />
              <Text style={styles.address}>www.jachai.com</Text>
            </View>
          </View>
          {/* invoiceArea */}
          <View style={styles.invoiceArea}>
            <View style={{ marginRight: "30px" }}>
              <View>
                <Image src={InvoiceLogo} style={styles.icon} />
              </View>
              <View>
                <Text style={styles.shopName}>Invoice To</Text>
              </View>
              <View>
                <Text style={styles.name}>
                  {data.merchantData?.companyName}
                </Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Mob: {data.merchantData?.mobileNumber?.replace("+88", "")}
                </Text>
              </View>
              <View>
                <Text style={styles.address}>
                  Email: {data.merchantData?.emailAddress}
                </Text>
              </View>
            </View>
            <View style={styles.invoiceRight}>
              <View style={styles.table}>
                <View style={styles.tableLeft}>
                  <View style={styles.tr}>
                    <Text style={styles.th}>Total Paid Out</Text>
                  </View>
                  <View style={styles.tr}>
                    <Text style={styles.th}>Invoice Date</Text>
                  </View>
                  <Text style={styles.th}>Invoice #</Text>
                </View>
                <View style={styles.tableRight}>
                  <View style={styles.tr}>
                    <Text style={styles.td}>
                      BDT {Math.abs(Math.round(data?.invoicData?.receivable))}
                    </Text>
                  </View>
                  <View style={styles.tr}>
                    <Text style={styles.td}>
                      {moment(data?.invoiceData?.createdAt).format("LL")}
                    </Text>
                  </View>
                  <Text style={styles.td}>{data?.invoicData?.invoiceId}</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Summary Table */}
          <View
            style={{
              border: "1px solid #d5d5d5",
              borderRadius: "3px",
              marginBottom: "16px",
            }}
          >
            <View style={styles.summaryTable}>
              <View>
                <Text style={styles.th}>Order ID</Text>
                {order?.data?.map((order: any, i: number) => (
                  <Text style={styles.td} key={i}>
                    #{order?.orderId}
                  </Text>
                ))}
              </View>
              <View>
                <Text style={styles.th}>Shipping Location</Text>
                {order?.data?.map((order: any, i: number) => (
                  <Text style={[styles.td, { textTransform: "capitalize" }]}>
                    {order?.parcelOrder?.recipientAddress}
                  </Text>
                ))}
              </View>
              <View>
                <Text style={styles.th}>Delivery Charge</Text>
                {order?.data?.map((order: any, i: number) => (
                  <Text style={styles.td} key={i}>
                    {Math.round(order?.parcelOrder?.deliveryCharge)}
                  </Text>
                ))}
              </View>
              <View>
                <Text style={styles.th}>Amount To Collect</Text>
                {order?.data?.map((order: any, i: number) => (
                  <Text style={styles.td} key={i}>
                    {Math.round(order?.parcelOrder?.amountToCollect)}
                  </Text>
                ))}
              </View>
              <View>
                <Text style={styles.th}>Total Paid</Text>
                {order?.data?.map((order: any, i: number) => (
                  <Text style={styles.td} key={i}>
                    {Math.abs(Math.round(order?.totalPaid))}
                  </Text>
                ))}
              </View>
            </View>
          </View>
          {/* Footer */}
          <View style={{ backgroundColor: "#091328", borderRadius: "3px" }}>
            <View style={styles.summaryTable}>
              <Text style={[styles.th, { color: "#fff" }]}>TOTAL</Text>
              <Text style={[styles.th, { color: "#fff" }]}>=</Text>
              <Text style={[styles.th, { color: "#fff" }]}>
                {Math.abs(Math.round(data?.invoicData?.fee))}
              </Text>
              <Text style={[styles.th, { color: "#fff" }]}>
                {Math.abs(Math.round(data?.invoicData?.collectedAmount))}
              </Text>
              <Text style={[styles.th, { color: "#fff" }]}>
                {Math.abs(Math.round(data?.invoicData?.receivable))}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default MerchantInvoice;
