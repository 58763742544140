import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Typography, Card } from "antd";
import MainLayout from "../Layouts";
import PageTitle from "../common/PageTitle";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../common/Loader";
const { Title } = Typography;

const Dashboard = (): ReactElement => {
  // All Merchant API
  const token = useSelector((state: any) => state.token.value);
  const [totalMerchant, seTotalMerchant] = useState<any>({
    loading: false,
    list: [],
  });
  const [totalOrder, seTotalOrder] = useState<any>({
    loading: false,
    list: [],
  });
  const [unpaidOrder, setUnpaidOrder] = useState<any>({
    loading: false,
    list: [],
  });
  const [paidOrder, setPaidOrder] = useState<any>({
    loading: false,
    list: [],
  });

  const orderUrl = `${process.env.REACT_APP_ORDER_API}/admin/order?type=JC_PARCEL`;
  const url = `${process.env.REACT_APP_RIDER_API}/business/all`;

  const fetchMerchantData = useCallback(async () => {
    seTotalMerchant({ loading: true, data: [] });
    try {
      const response = await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          seTotalMerchant({ loading: false, data: res?.data });
        });
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    fetchMerchantData();
  }, []);

  // All Order Api

  const fetchOrderList = useCallback(async (paymentStatus) => {
    seTotalOrder({ loading: true, data: [] });
    setUnpaidOrder({ loading: true, data: [] });
    setPaidOrder({ loading: true, data: [] });
    try {
      const response = await axios
        .get(
          `${orderUrl}` +
            (paymentStatus ? `&paymentStatus=${paymentStatus}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          !paymentStatus && seTotalOrder({ loading: false, data: res?.data });
          paymentStatus === "UNPAID" &&
            setUnpaidOrder({ loading: false, data: res?.data });
          paymentStatus === "Paid" &&
            setPaidOrder({ loading: false, data: res?.data });
        });
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    fetchOrderList("");
    fetchOrderList("UNPAID");
    fetchOrderList("Paid");
  }, []);

  return (
    <MainLayout>
      <>
        <PageTitle title={"Dashboard"} />
        {totalMerchant.loading ||
        totalOrder.loading ||
        unpaidOrder.loading ||
        paidOrder?.loading ? (
          <Loader />
        ) : (
          <div className="md:grid grid-cols-2 xl:grid-cols-4 gap-4">
            <div>
              <Card>
                <div className="md:flex justify-between">
                  <div className="mb-3">
                    <Title level={5}>Total Merchants</Title>
                  </div>
                  <div className="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      className="w-6 h-6 stroke-teal-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-3xl font-light">
                  {totalMerchant?.data?.totalElements || 0}
                </p>
              </Card>
            </div>
            <div>
              <Card>
                <div className="md:flex justify-between">
                  <div className="mb-3">
                    <Title level={5}>Total Orders</Title>
                  </div>
                  <div className="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      className="w-6 h-6 stroke-yellow-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-3xl font-light">
                  {totalOrder?.data?.totalElements}
                </p>
              </Card>
            </div>
            <div>
              <Card>
                <div className="md:flex justify-between">
                  <div className="mb-3">
                    <Title level={5}>Total Unpaid Orders</Title>
                  </div>
                  <div className="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      className="w-6 h-6 stroke-red-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-3xl font-light">
                  {unpaidOrder?.data?.totalElements}
                </p>
              </Card>
            </div>
            <div>
              <Card>
                <div className="md:flex justify-between">
                  <div className="mb-3">
                    <Title level={5}>Total Paid Orders</Title>
                  </div>
                  <div className="mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      className="w-6 h-6 stroke-green-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-3xl font-light">
                  {paidOrder?.data?.totalElements}
                </p>
              </Card>
            </div>
          </div>
        )}
      </>
    </MainLayout>
  );
};
export default Dashboard;
