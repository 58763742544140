import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import MainLayout from "../../Layouts";
import MerchantLayout from "../Layout";
import Titleh4 from "../../common/Titleh4";
import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setMerchant } from "../../../redux/state/merchant/merchantSlice";

const MerchantDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const token = useSelector((state:any) => state.token.value);
  const route = useParams();
  // Api Start
  const [merchantData, setMerchantData] = useState<any>({
    loading: false,
    data: [],
  });
  const url = `${process.env.REACT_APP_RIDER_API}/business/${route.merchantId}`;
  const fetchData = useCallback(async () => {
    setMerchantData({ loading: true, data: [] });
    try {
      const response = await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMerchantData({ loading: false, data: res?.data?.business });
          dispatch(setMerchant(res?.data?.business));
        });
    } catch (error) {
      dispatch(setMerchant(""));
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
  // Api End
  return (
    <MainLayout>
      <MerchantLayout>
        <Titleh4 title="All Details" />
        <Divider />
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="mb-2">
              <div className="merchant-single">
                <h2 className="text-black font-semibold capitalize">
                  Merchant Name
                </h2>
                <div>
                  {merchantData?.data?.companyName || "Unknown Merchant"}
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="merchant-single">
                <h2 className="text-black font-semibold capitalize">
                  Email Addess
                </h2>
                <p>{merchantData?.data?.emailAddress || ""}</p>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-2">
              <div className="merchant-single">
                <h2 className="text-black font-semibold capitalize">
                  Mobile Number
                </h2>
                <p>
                  {merchantData?.data?.mobileNumber?.replace("+88", "") || ""}
                </p>
              </div>
            </div>
            <div className="mb-2">
              <div className="merchant-single">
                <h2 className="text-black font-semibold capitalize">
                  COD Charge
                </h2>
                <p>&#2547; {merchantData?.data?.codCharge || 0}</p>
              </div>
            </div>
          </div>
          {merchantData?.data?.mfsInfo?.length && (
            <div className="mb-2">
              <div className="merchant-single">
                <Divider orientation="left" className="!mt-0">MFS Info</Divider>
                <p>
                  <span className="mr-2"></span>
                  <span></span>
                </p>
              </div>
            </div>
          )}
          {merchantData?.data?.bankInfo?.length && (
            <div className="mb-2">
              <div className="merchant-single">
                <Divider orientation="left" className="!mt-0">Bank Info</Divider>
                <p>
                  <span className="mr-2"></span>
                  <span></span>
                </p>
              </div>
            </div>
          )}
          {merchantData?.data?.dhakaSuburbs && (
            <div className="mb-2">
              <div className="merchant-single">
                <Divider orientation="left" className="!mt-0">Dhaka Suburbs</Divider>
                <p>
                  <span className="mr-2">Charge:</span>
                  <span>&#2547; {merchantData?.data?.dhakaSuburbs.charge}</span>
                </p>
                <p>
                  <span className="mr-2">Next:</span>
                  <span>&#2547; {merchantData?.data?.dhakaSuburbs.next}</span>
                </p>
                <p>
                  <span className="mr-2">Time:</span>
                  <span>{merchantData?.data?.dhakaSuburbs.time}</span>
                </p>
              </div>
            </div>
          )}
          {merchantData?.data?.insideDhaka && (
            <div className="mb-2">
              <div className="merchant-single">
                <Divider orientation="left" className="!mt-0">Inside Dhaka</Divider>
                <p>
                  <span className="mr-2">Charge:</span> &#2547;{" "}
                  {merchantData?.data?.insideDhaka.charge}
                </p>
                <p>
                  <span className="mr-2">Next:</span> &#2547;{" "}
                  {merchantData?.data?.insideDhaka.next}
                </p>
                <p>
                  <span className="mr-2">Time:</span>{" "}
                  {merchantData?.data?.insideDhaka.time}
                </p>
              </div>
            </div>
          )}
          {merchantData?.data?.outsideDhaka && (
            <div className="mb-2">
              <div className="merchant-single">
                <Divider orientation="left" className="!mt-0">Outside Dhaka</Divider>
                <p>
                  <span className="mr-2">Charge:</span> &#2547;{" "}
                  {merchantData?.data?.outsideDhaka.charge}
                </p>
                <p>
                  <span className="mr-2">Next:</span> &#2547;{" "}
                  {merchantData?.data?.outsideDhaka.next}
                </p>
                <p>
                  <span className="mr-2">Time:</span>{" "}
                  {merchantData?.data?.outsideDhaka.time}
                </p>
              </div>
            </div>
          )}
          {merchantData?.data?.sameDayDelivery && (
            <div className="mb-2">
              <div className="merchant-single">
                <Divider orientation="left" className="!mt-0">Same Day Delivery</Divider>
                <p>
                  <span className="mr-2">Charge:</span> &#2547;{" "}
                  {merchantData?.data?.sameDayDelivery.charge}
                </p>
                <p>
                  <span className="mr-2">Next:</span> &#2547;{" "}
                  {merchantData?.data?.sameDayDelivery.next}
                </p>
                <p>
                  <span className="mr-2">Time:</span>{" "}
                  {merchantData?.data?.sameDayDelivery.time}
                </p>
              </div>
            </div>
          )}
        </div>
      </MerchantLayout>
    </MainLayout>
  );
};
export default MerchantDetails;
