import React, { ReactElement } from "react";
import MainLayout from "../Layouts";
import InvoiceList from "../Merchants/Settled/InvoiceList";

const Invoices = (): ReactElement => {
  return (
    <MainLayout>
      <InvoiceList />
    </MainLayout>
  );
};
export default Invoices;
