import * as React from "react";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import SubmitButton from "../common/SubmitButton";
import bgImg from "../../images/curve.png";
import loginImg from "../../images/login.png";
import logo from "../../images/logo.svg";
import { useCallback } from "react";
import axios from "axios";
import { errorNotification, responseNotification } from "../../utils/notify";
import { useDispatch } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { login } from "../../redux/state/login/loginSlice";
import { setToken } from "../../redux/state/token/tokenSlice";

const Login = (): any => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const onFinish = useCallback(async (data) => {
    const emailData = data?.email;
    const passwordData = data?.password;
    const url = `${process.env.REACT_APP_AUTH_API}/admin/login`;
    axios
      .post(url, data)
      .then((response) => {
        dispatch(
          login({
            name: response?.data?.name,
            profilePicture: response?.data?.profilePicture,
            email: emailData,
            password: passwordData,
          })
        );
        dispatch(setToken(response?.data?.token));
        responseNotification("You've Logged In..");
      })
      .catch((error) => {
        dispatch(setToken(""));
        errorNotification("Wrong Password..");
      });
  }, []);

  return (
    <section className="login-wrapper p-4 min-h-screen flex items-center">
      {/* Shape Image */}
      <img className="wave hidden md:block" src={bgImg} alt="image" />
      <div className="container mx-auto">
        <div className="flex justify-evenly my-4">
          {/* Login Image */}
          <div className="hidden lg:block self-center">
            <img src={loginImg} alt="image" className="object-cover" />
          </div>
          {/* Login Content */}
          <div className="self-center p-5">
            <img src={logo} alt="logo" className="object-cover w-64 mb-12" />
            <h2 className="mb-4 custom-title">Login Here</h2>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              form={form}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  type="email"
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <SubmitButton
                className="!text-white bg-cyan-600 hover:!bg-cyan-500 border-none"
                buttonText="Login Now"
              />
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
